import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {mobile, tablet} from '~styles/global';
import {Link} from 'gatsby';
import Section from '~components/Section';
import useSiteSettings from '~utils/useSiteSettings';
import SanityLink from '~components/SanityLink';
import Image from '~components/Image';

const MobileMenu = ({className, open, data}) => {
	const {phoneNumber, emailAddress, instagramUrl, footerText, mobileMenuBackgroundImage} = useSiteSettings();

	return (
		<Wrap className={className} open={open}>
			<BackgroundImage image={mobileMenuBackgroundImage} cover />
			<StyledSection>
				<Container>
					<Nav>
						<NavInner>
							<NavLink to={`/${data?.sanityArchivePage?.slug?.current}`} activeClassName="active">
								All Projects
							</NavLink>
							<NavLink to={'/production'} activeClassName="active">
								Production
							</NavLink>
							<NavLink to={`/${data?.sanityPonyShow?.slug?.current}`} activeClassName="active">
								A-List Directors
							</NavLink>
							<NavLink to={`/${data?.sanityPost?.slug?.current}`} activeClassName="active">
								Post
							</NavLink>
							{/* <NavLink to={`/${data?.sanityDirectors?.slug?.current}`} activeClassName="active">
								Directors
							</NavLink> */}
							<NavLink to={'/ooh'} activeClassName="active">
								OOH
							</NavLink>
							<NavLink to={'/stills'} activeClassName="active">
								Stills
							</NavLink>
							<NavLink to="/events" activeClassName="active">
								Events
							</NavLink>
							<NavLink to="/studio" activeClassName="active">
								Studio
							</NavLink>
							<NavLink to={`/${data?.sanityAbout?.slug?.current}`} activeClassName="active">
								About
							</NavLink>
							{data?.sanitySiteSettings?.showNews && (
								<NavLink to={`/${data?.sanityNews?.slug?.current}`} activeClassName="active">
									News
								</NavLink>
							)}
						</NavInner>
					</Nav>
					<Footer className="sans">
						<FooterInner>
							<FooterLink to={'/about-us'}>About</FooterLink>
							<Tel href={`tel:${phoneNumber}`}>{phoneNumber}</Tel>
							<Email
								href=""
								link={{
									url: `mailto:${emailAddress}`,
								}}
							>
								{emailAddress}
							</Email>
						</FooterInner>
					</Footer>
				</Container>
			</StyledSection>
		</Wrap>
	);
};

const Wrap = styled.div`
	display: none;
	position: fixed;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	z-index: 99;
	pointer-events: ${(props) => (props.open ? 'all' : 'none')};
	color: var(--white);
	opacity: ${(props) => (props.open ? '1' : '0')};
	transition: opacity 0.5s;
	transition-delay: ${(props) => (props.open ? '0s' : '0.3s')};
	${tablet} {
		display: block;
	}
`;
const BackgroundImage = styled(Image)`
	position: fixed;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	transform: scale(1.4);
`;
const StyledSection = styled(Section)`
	height: 100%;
	> div {
		height: 100%;
	}
`;
const Container = styled.div`
	grid-column: 3/13;
	height: 100%;
	display: flex;
	flex-direction: column;
`;
const Nav = styled.nav`
	flex: 1;
	display: flex;
	align-items: center;
`;
const NavInner = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 116px;
`;
const NavLink = styled(Link)`
	width: 100%;
	font-size: 36px;
	line-height: 44px;
	&.active {
		color: var(--pink);
	}
	transition: color 0.3s;
`;
const Footer = styled.div`
	height: 36%;
	border-top: 1px solid var(--white);
	box-sizing: border-box;
	padding-top: 11px;
`;
const FooterInner = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
const FooterLink = styled(Link)`
	margin-bottom: 6px;
	display: block;
	width: 100%;
`;
const Tel = styled.a`
	margin-bottom: 6px;
	display: block;
	width: 100%;
`;
const Email = styled(SanityLink)`
	display: block;
	width: 100%;
`;

MobileMenu.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	data: PropTypes.object,
};

export default MobileMenu;
